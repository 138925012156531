import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ePubPress",
  "description": "E-Book Creator for Wordpress Blogs",
  "order": 5,
  "thumb": "assets/thumb.png",
  "slug": "epubpress",
  "client": "Personal Project",
  "category": "Web App",
  "tools": "React, Material UI",
  "year": 2021,
  "github": "https://github.com/jlnbxn/epub-press",
  "website": "https://epubpress.netlify.app",
  "brandColor": "#E54112",
  "summary": "ePubPress allows users to turn almost any Wordpress site into an easily digestible ebook, thanks to Wordpress’s JSON API and a nifty trick of reverse proxying image links."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`The Problem`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`There are a lot of blogs with high quality content — sadly, the reading experience often doesn’t do their creators justice.`}</p><p parentName="div">{`While there are methods abound for making single posts and articles more readable, doing so for the whole site is a different matter.`}</p></div></div>
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`The Solution`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`Create a web app that allows one to enter a Wordpress URL, and be returned an ebook for a more convenient reading experience.`}</p></div></div>
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`Outcome`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`ePubPress is a web app that allows you to enter an URL to a Wordpress site and get back a fully-fledged ebook, with each chapter corresponding to a post.`}</p><p parentName="div">{`This way, you can archive your favorite blogs, and indulge all at once in any new gem you fund — even offline.`}</p><p parentName="div">{`Get the full advantage of the tools ebook readers come with, including the highlighting of text passages, bookmarking positions, and adding notes.`}</p><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1955px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "71.8%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Enter URL",
              "title": "Enter an URL to a Wordpress(.com) blog...",
              "src": "/static/a00bbfcd4078cf8734589581c3aec8e3/4d915/enter-url.png",
              "srcSet": ["/static/a00bbfcd4078cf8734589581c3aec8e3/0eb09/enter-url.png 500w", "/static/a00bbfcd4078cf8734589581c3aec8e3/1263b/enter-url.png 1000w", "/static/a00bbfcd4078cf8734589581c3aec8e3/4d915/enter-url.png 1955w"],
              "sizes": "(max-width: 1955px) 100vw, 1955px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Enter an URL to a Wordpress(.com) blog...`}</figcaption>{`
  `}</figure><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1955px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "71.8%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Downloading Progress",
              "title": "...observe the progress bar..",
              "src": "/static/4a2a79d39e7580f76d215ed849c9c9cd/4d915/downloading.png",
              "srcSet": ["/static/4a2a79d39e7580f76d215ed849c9c9cd/0eb09/downloading.png 500w", "/static/4a2a79d39e7580f76d215ed849c9c9cd/1263b/downloading.png 1000w", "/static/4a2a79d39e7580f76d215ed849c9c9cd/4d915/downloading.png 1955w"],
              "sizes": "(max-width: 1955px) 100vw, 1955px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`...observe the progress bar..`}</figcaption>{`
  `}</figure><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1190px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75.4%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Book",
              "title": "...enjoy reading!",
              "src": "/static/3f957dc1320b35109cc52bc089172dae/ba099/book.png",
              "srcSet": ["/static/3f957dc1320b35109cc52bc089172dae/0eb09/book.png 500w", "/static/3f957dc1320b35109cc52bc089172dae/1263b/book.png 1000w", "/static/3f957dc1320b35109cc52bc089172dae/ba099/book.png 1190w"],
              "sizes": "(max-width: 1190px) 100vw, 1190px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`...enjoy reading!`}</figcaption>{`
  `}</figure></div></div>
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`Background`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`There are a lot of blogs out there, with a wide range of quality. Some of them might as well be sold as books, so good and consistent is their quality. To me, `}<a parentName="p" {...{
            "href": "https://waitbutwhy.com/"
          }}>{`waitbutwhy.com`}</a>{` and `}<a parentName="p" {...{
            "href": "https://bodyrecomposition.com/"
          }}>{`bodyrecomposition.com`}</a>{` are such examples.`}</p><p parentName="div">{`While their content differs, the quality in their respective fields doesn’t: waitbutwhy explore scientific concepts and ideas in a humorous, and memorable manner, teaching valuable information effortlessly.`}</p><p parentName="div">{`Bodyrecomposition meanwhile is one of the greatest treasure troves when it comes to evidence based training and nutrition information.`}</p><p parentName="div">{`As such, the high standard to which these blogs are held by their authors makes each article a chapter in a book whose writing is ongoing - which is the way I wanted to consume them in.`}</p><p parentName="div">{`Back in the day I used to create small applications that used to loop through a blog’s RSS feed and feed them into Evernote. Later on, I decided to bundle them up into a much more convenient ebook format, which involved a lot of manual labour.`}</p><p parentName="div">{`At the time, this approach served its purpose, allowing me to binge read and annotate to my liking. Recently though, I thought of revisiting this idea, with my backlog of unread material adding up and Wordpress nowadays having a fully working REST API, which simplifies the process a lot.`}</p></div></div>
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`How It Works`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`The application asks the user for a Wordpress URL. A little background check is run on the chosen site, querying its source for evidence of  Wordpress related code.`}</p><p parentName="div">{`On success,  a request is sent to the API, looping through the posts and binding them into a book. (Wordpress.com sites have slightly different endpoints, but otherwise act the same.)`}</p><p parentName="div">{`Ticking the “Include Images” box fetches each image on each post, creates a blob and attaches it as a local reference in place of the original link. Naturally, this lengthens the process quite a bit, which is why I decided to include a progress indicator, as to not give off the impression of an unresponsive program.`}</p><p parentName="div">{`Where available, other information gathered through the API is used to populate the .epub’s metadata field, such as the site author, categories and publisher.`}</p></div></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      